/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Modal } from "react-bootstrap";
import marketersCoupons from "../../campaign-marketers-coupons.json";
import { useSelector } from "react-redux";
import { history, hideLoader } from "../_helpers";
import { userActions } from "../_actions";
import { useDispatch } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import * as axios from 'axios';
import HubspotForm from 'react-hubspot-form'

const hders = new Headers();
hders.append("Content-Type", "application/json");
hders.append("Accept", "application/json");
hders.append("Access-Control-Allow-Origin", window.location.origin);
hders.append("Access-Control-Allow-Credentials", "true");

let stripePromise;

const Selected = (props) => {
  const dispatch = useDispatch();
  const { cartInfo } = useSelector((state) => state.users);
  const [cartItems, updateCartItems] = useState([]);
  const [packageDetails, updatePackageDetails] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showAskEmail, setShowAskEmail] = useState(false);
  const [hubspotInfo, sethHubspotInfo] = useState(false);
  const [getFree, setGetFree] = useState(false);
  const [coupon, setCoupon] = useState('');
  const [badCoupon, setBadCoupon] = useState(false);

  const handleDetailsClose = () => setShowDetails(false);
  const handleAskEmailClose = () => setShowAskEmail(false);

  function displayDetails(title) {
    updatePackageDetails(cartItems.filter((x) => x.Title === title)[0]);
    setShowDetails(true);
  }

  function openAskEmailCampaignFree() {
    setShowAskEmail(true);
  }

  function handleSendHubSpotForm() {
    sethHubspotInfo(true);
  }

  useEffect(() => {
    hideLoader();
    if (cartInfo) {
      updateCartItems(cartInfo);
      localStorage.setItem("cartInfo", JSON.stringify(cartInfo));
    }
  }, [cartInfo]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${window.location.origin}/publickey`;
        const result = await axios.get(
          url,
          {
            headers: hders,
          }
        );
        stripePromise = loadStripe(result.data.publickey);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();

    const cartStorageInfo = localStorage.getItem("cartInfo") || [];
    if (cartStorageInfo == null || cartStorageInfo.length === 0) {
      history.push("/campaign");
    } else {
      updateCartItems(JSON.parse(cartStorageInfo));
    }
  }, []);

  function bindHeaders() {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    headers.append("Access-Control-Allow-Origin", window.location.origin);
    headers.append("Access-Control-Allow-Credentials", "true");
    return headers;
  }

  function cartClickEvent(campaign) {
    var array = [...cartItems];
    var index = cartItems.findIndex((item) => item.Id === campaign.Id);
    if (index !== -1) {
      array.splice(index, 1);
      updateCartItems(array);
      localStorage.setItem("cartInfo", JSON.stringify(array));
      if (array.length === 0) {
        history.push("/campaign");
      }
    }
  }

  function handleBackButton() {
    dispatch(userActions.fetchPreviousCart(cartItems));
    history.push("/campaign");
  }

  function checkFreeCampaign() {
    const campaignsAskEmail = cartItems.filter((cartItem) => cartItem.askForEmail);
    return campaignsAskEmail.length > 0 ? true : false;
  }

  async function afterSendEmail(event) {
    sethHubspotInfo(true);
    await new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      },2000)
    })
    let body = {
      title : cartItems.map((x) => x.Title).toString()
    }
    let totalPrice = cartItems.map((x) =>  (x.isFree ? 0 : x.Price)).reduce((acc, cur) => acc + cur, 0).toFixed(2);
    const noCheckoutParam = '?nocheckout=true';
    if(getFree || totalPrice === '0.00') {
      history.push({
        pathname: `/success`,
        search: noCheckoutParam,
        state: {
          campaigns: body
        }
      });
    } else {
      paymentProcess();
    }
  }

  function noPaymentProcess(noCheckoutParam) {
    let body = {
      title : cartItems.map((x) => x.Title).toString()
    }
    const free = checkFreeCampaign();
    if(free && !hubspotInfo) {
      openAskEmailCampaignFree();
    } else {
      history.push({
        pathname: `/success`,
        search: noCheckoutParam,
        state: {
          campaigns: body
        }
      });
    }
  }

  async function paymentProcess() {
    let headers = bindHeaders();
    const stripe = await stripePromise;
    let totalPrice = cartItems.map((x) =>  (x.isFree ? 0 : x.Price)).reduce((acc, cur) => acc + cur, 0).toFixed(2);
    let body = {
      total : totalPrice,
      title : cartItems.map((x) => x.Title).toString()
    }
    let url = window.location.origin + `/create-checkout-session`;
    const response = await axios.post(
      url,
      {
        data: body,
        headers,
      }
    );
    const result = await stripe.redirectToCheckout({
      sessionId: response.data.id,
    });
    if (result.error) {
    }
  }

  const handleClickPaymentProcess = async (event) => {
    let totalPrice = cartItems.map((x) =>  (x.isFree ? 0 : x.Price)).reduce((acc, cur) => acc + cur, 0).toFixed(2);
    if(totalPrice === '0.00') {
      const noCheckoutParam = '?nocheckout=true';
      noPaymentProcess(noCheckoutParam);
    } else {
      const free = checkFreeCampaign();
      if(free && !hubspotInfo) {
        openAskEmailCampaignFree();
      } else {
        paymentProcess();
      }
    }
  }

  const getCampaignUsingCoupon = async (event) => {
    setBadCoupon(false);
    const currentCoupon = coupon;
    if(marketersCoupons.COUPONS.includes(currentCoupon)) {
      const noCheckoutParam = '?nocheckout=true';
      console.log('no payment process...');
      setGetFree(true);
      noPaymentProcess(noCheckoutParam);
    } else {
      setBadCoupon(true);
      setGetFree(false);
    }
  };

  const handleOnChanceCoupon = async (event) => {
    setCoupon(event.target.value);
    setBadCoupon(false);
    setGetFree(false);
  };

  return (
    <div>
      <Sidebar />
      <div className="compaign-right-content-main p-3">
        <Header />
        <section className="campaign-wizard-step-main compaign-list-box-back p-md-3 mt-3">
          <div className="container-fluid">
            <div className="container">
              <div className="row py-5 ps-4 mx-xl-3">
                <div className="col-12 d-flex flex-row">
                  <h3 className="ind-4-sec-page-title">
                    Your selected campaigns
                  </h3>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="table-responsive p-xl-5 pt-xl-2">
                <table className="table">
                  <tbody>
                    {cartItems.map(function (campaign, index) {
                      return (
                        <tr className="align-middle" key={campaign.Id}>
                          <td width="8%">
                            <span
                              key={index}
                              dangerouslySetInnerHTML={{
                                __html: campaign.SmallIcon,
                              }}
                            ></span>
                          </td>
                          <td className="py-4">
                            <h5 className="fw-bold ind-4-sec-page-content">
                              {campaign.Title}{" "}
                            </h5>
                            <h6 className="ind-4-sec-page-text">
                              {campaign.Description}
                            </h6>
                          </td>
                          <td className="py-4 text-end">
                            <a
                              href="#modal3"
                              className="back-btn text-decoration-underline ind-4-sec-page-text"
                              onClick={() => displayDetails(campaign.Title)}
                            >
                              See Details
                            </a>
                          </td>
                          <td className="py-4 text-end">
                            { campaign.isFree ? <h3 className="price">Free</h3> : <h4 className="price">${campaign.Price}</h4> } 
                          </td>
                          <td className="py-4">
                            <a
                              onClick={(e) => cartClickEvent(campaign)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                className="bi bi-x back-btn ind-4-sec-page-close"
                                viewBox="0 0 16 16"
                              >
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                              </svg>
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="container">
              <div className="row px-xl-5">
                <div className="col-sm-3 col-12 p-3">
                  <span
                    onClick={() => handleBackButton()}
                    className="back-btn back back-btn-hover"
                  >
                    <span className="ps-2 ind-4-sec-page-text back-btn-hover">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-left ind-4-sec-page-close back-btn-hover"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                        />
                      </svg>{" "}
                      Back
                    </span>
                  </span>
                </div>
                <div className="col-sm-9 col-12 p-3 d-flex justify-content-end">
                  <div className="px-lg-5 px-4 text-color pt-2">
                    <h5 className="selected-total">
                      Your total: $
                      {cartItems
                        .map((x) => (x.isFree ? 0 : x.Price))
                        .reduce((acc, cur) => acc + cur, 0)
                        .toFixed(2)}
                    </h5>
                  </div>
                  <div className="ind-4-button">
                    <button type="button"
                      className="ind-4-sec-page-button hover-button"
                      onClick={handleClickPaymentProcess}
                      role="link"
                    >
                      Checkout
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row px-xl-5">
                <div className="col-12 pt-2 text-end">
                  <p className="ind-4-sec-page-text">
                    {" "}
                    You'll be prompted to enter your billing info.
                  </p>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row px-xl-5">
                <div className="col-12 pt-2 text-end">
                  <input type="text" name="txtCoupon" value={coupon} onChange={(event) => handleOnChanceCoupon(event)}/>
                  {
                    badCoupon && 
                    <p className="ind-4-sec-page-text text-danger p-1 m-0">
                      Invalid coupon
                    </p>
                  }
                  <p className="ind-4-sec-page-text">
                    Please enter a coupon code to get the campaign for free.
                  </p>
                  <button type="button"
                    className="ind-4-sec-page-button hover-button"
                    onClick={getCampaignUsingCoupon}
                    role="link"
                  >
                    Get free 
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            {/*<!-- Modal See Details dialog -->*/}
            <Modal show={showDetails} className="modal-dialog-image">
              <Modal.Body>
                <div className="row">
                  <div className="text-end modal-button">
                    <button
                      type="button"
                      className="btn-close text-dark p-3  ind-4-sec-page-close"
                      onClick={() => handleDetailsClose()}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="campaign-see-details-modal">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: packageDetails == null ? "" : (packageDetails.Details == null ? "" : packageDetails.Details.ImagePath),
                        }}
                      ></span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 ps-4">
                    <div className="pe-3 pt-3">
                      <h3 className="modal-see-title">
                      {
                          packageDetails == null ? 0: (packageDetails.isFree ? "Free" : `$${packageDetails.Price}`)
                        }
                      </h3>
                    </div>
                    <div className="pe-4">
                      <h3 className="modal-see-title">
                      {packageDetails == null ? "" : packageDetails.Title}
                      </h3>
                      <p className="modal2-contant">
                      {packageDetails == null ? "" : (packageDetails.Details == null ? "" : packageDetails.Details.Description)}
                      </p>
                    </div>
                    <div className="pe-4">
                      <ul className="model-list">
                        <li>
                          <div className="row ps-2">
                            <div className="col-1 pt-1 gx-1 d-flex">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-check-circle pe-2 icon-size"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg>
                            </div>
                            <div className="col-10 gx-2">
                              <p className="modal2-contant">
                              {packageDetails == null ? "" : (packageDetails.Details == null ? "" : packageDetails.Details.AssetsIncluded)}
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="row ps-2">
                            <div className="col-1 pt-1 gx-1 d-flex">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-check-circle pe-2 icon-size"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg>
                            </div>
                            <div className="col-10 gx-2">
                              <p className="modal2-contant">
                                Software Requirements:{" "}
                                {packageDetails == null ? "" : (packageDetails.Details == null ? "" : packageDetails.Details.SoftwareRequirements)}
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="row ps-2">
                            <div className="col-1 pt-1 gx-1 d-flex">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-check-circle pe-2 icon-size"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                              </svg>
                            </div>
                            <div className="col-10 gx-2">
                              <p className="modal2-contant">
                                Delivery Time:{" "}
                                {packageDetails == null ? "" : (packageDetails.Details == null ? "" : packageDetails.Details.DeliveryTime)}
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal show={showAskEmail} className="modal-dialog-image">
              <Modal.Body>
                <div className="row">
                  <div className="text-end modal-button">
                    <button
                      type="button"
                      className="btn-close text-dark p-3  ind-4-sec-page-close"
                      onClick={() => handleAskEmailClose()}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="col-12">
                    <HubspotForm
                      portalId='313589'
                      formId='9efacc93-b6bd-41ff-83df-33225f88f717'
                      onSubmit={(event) => afterSendEmail(event)}
                      onReady={(form) => console.log('Form ready!')}
                      loading={<div>Loading...</div>}
                      region="na1"
                    />
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Selected;
